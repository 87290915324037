import {
    InfoModalContent,
    InfoModalDoubleContent,
} from "../../models/common/common";

const quality: InfoModalContent = {
    title: "Quality",
    content: [
        "Choose our quality experience and our quality assistance to live your best esperienza italiana. Spend your nights in beautiful 4-stars hotels and start your days getting around Italy with our beloved Italian Experts. You’ll share your tour with  4 to 10 people that, like you, share the same interests,",
    ],
    listElements: [
        "4-stars hotel",
        "4 to 10 people group",
        "Our quality assistance",
        "Italian Experts only",
    ],
};

const premium: InfoModalContent = {
    title: "Premium",
    content: [
        "If you want to live an exclusive esperienza italiana, our premium experience is perfect for you. You’ll be staying at 4-star S or 5-star hotels and receive the best premium assistance you can get. Your own private group will be guided by one of our Italian Experts, making your trip truly unforgettable.",
    ],
    listElements: [
        "4-star S or 5-star hotel",
        "Private outings",
        "Premium assistance",
        "Italian Experts only",
    ],
};

export const qualityPremiumContent: InfoModalDoubleContent = {
    title: "Packages Differences",
    content: [quality, premium],
};

export const dateContent: InfoModalContent = {
    title: "Our prices based on seasons",
    content: [
        "You might see different prices based on which dates you are choosing. Our High Season goes from 20/06/23 to 30/06/2023 and from 22/08/23 to 31/10/23. Shoulder season from 1/07/23 to 3/08/2023 and our Low Season lasts from 4/08/2023 to 21/08/2023.",
        "If some of the dates are not selectable, that means that our tours are not departing that day.",
    ],
    listElements: [],
};
export const priceContent: InfoModalContent = {
    title: "Tariffs & seasons",
    content: [
        "All our packages can be purchased all year long, we ensure daily departures for our customers, however you might see different prices displayed on the website depending on the period selected.",
        "If you wish to know more about peak season, availability during it and get a personal assistance on payment plans - get in touch with us.",
    ],
    listElements: [],
};
export const nightsContent: InfoModalContent = {
    title: "Feel free to book more nights",
    content: [
        "If you want to arrive earlier, you can book a night before the start of the tour. Same thing if you need to stay an additional night after the ending of the trip.",
    ],
    listElements: [],
};

export const languageContent: InfoModalContent = {
    title: "Our tours are for everyone",
    content: [
        "We want to give everyone the chance to experience our Esperienza Italiana. That’s why our italian Experts will guide you throughout your tour in the language you prefer among: English, Spanish and French.",
    ],
    listElements: [],
};

export const extraNightsContent: InfoModalContent = {
    title: "Do you want to spend extra nights at the Hotel?",
    content: [
        "Do you want to spend extra nights at the hotel? You can absolutely add more nights to your Italian vacation. To do so, please get in touch with our Booking Agent at <span style='text-decoration: underline'>booking@playitaly.com</span> before you proceed with your online booking.",
        "<div style='margin-top: 20px; text-align: center'>Do you have any other questions? Check out our <a href='/faq' style='color: red'>FAQ page</a></div>",
    ],
    textAsHtml: true,
    listElements: [],
};

export const qualityContent: InfoModalContent = {
    title: "Our quality experience",
    content: [
        "Choose our quality experience and our quality assistance to live your best esperienza italiana. Spend your nights in beautiful 4-stars hotel and start your days getting around places with our beloved Italian Experts. You’ll share your tour with 4 to 10 people.",
    ],
    listElements: [],
};

export const premiumContent: InfoModalContent = {
    title: "Our premium experience",
    content: [
        "If you want to live an exclusive esperienza italiana, our premium experience is perfect for you. You'll stay in exclusive accomodations and receive the best premium assistance you can get. Your own private group will be guided by one of our Italian Experts, making your trip truly unforgettable.",
    ],
    listElements: [],
};

export const cancellationPolicyContent: InfoModalContent = {
    title: "Which is the cancellation policy at Play Italy?",
    content: [
        "Our packages include the following terms: if the trip is cancelled 21 days or more before departure, travelers are entitled to a full refund of the total amount paid. If the trip is cancelled less than 21 days before departure, no refund will be provided.",
        "<div style='margin-top: 20px; text-align: center'>Do you have any other questions? Check out our <a href='/faq' style='color: red'>FAQ page</a></div>",
    ],
    textAsHtml: true,
    listElements: [],
};
